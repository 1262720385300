export const ambassadors = [
  {
    name: 'Vishalli Sreeppada',
    designation: 'Töölö Ambassador',
    address: 'Kondapur, Hyderabad',
    story:
      'I hold an MBA in Finance, which I completed in 2003 in Hyderabad. In 2013, I furthered my passion for education by becoming a trained Nursery Teacher in Bangalore. Over the years, I have gained valuable experience working in various schools, including KLAY in Hyderabad.\n In 2017, I embarked on an exciting journey by establishing my own playschool, catering to children aged 2–6 years. I am a certified Jolly Phonics and ABACUS teacher and a trained Special Educator, equipping me to provide a well-rounded and inclusive learning experience for my students. \n Storytelling is at the heart of my teaching approach. I believe stories spark curiosity, creativity, and a love for reading in children, and I take pride in creating an environment where young minds can thrive.',
    imgPath: 'vishalli.jpg',
  },
  {
    name: 'Jalpa Vishal Shah',
    designation: 'Töölö Ambassador',
    address: 'Sanathal, Ahmedabad',
    story:
      "Hi, I am Jalpa, mother of 7-year-old twins (a boy and a girl) who are both homeschooled. A reader inside me awoke when I discovered my daughter's interest in reading. I've started exploring various books because my kids have endless questions about different subjects. I worked as a counselor in a pre-primary school for three years. With Töölö, I found an excellent platform to work from home while providing my kids with books to foster their reading. I hope this will allow them to develop their imagination through stories. This will be great for their homeschooling, and I also get to spread the joy of reading amongst more children! I am happy to be part of the Töölö Family.\n Thank you.",
    imgPath: 'jalpa.jpg',
  },
  {
    name: 'Deepa Jagadeesh',
    designation: 'Töölö Ambassador',
    address: 'KR Puram, Bangalore',
    story:
      'With a heart devoted to the art of education I have embraced the role of a teacher with fervour and dedication. For me teaching is not just a profession but a chosen path where the joy of learning intertwines with the fulfilment of imparting knowledge. Armed with a bachelor’s degree in business management and certified Montessori trainer, I have carved a unique niche as a Montessori teacher, a captivating storyteller and an online Kannada tutor. Beyond the confines of the classroom, I find solace and delight in the pages of fiction and mythology. Immersing myself in these realms of imagination fuels my own creativity and enriches my teaching. Music fills my days, providing the rhythm to my lessons and a melody to my soul, while playing with kids ignites a sense of wonder and playfulness within me. I strongly feel reading when started at the right age with the right choice of books can foster a vivid reader’s group...when I thought of spreading the joy of reading.... My go to or one stop solution was "Töölö”.\n Happy to be a part of Töölö.',
    imgPath: 'deepa.jpg',
  },
  {
    name: 'Arpita Mishra',
    designation: 'Töölö Ambassador',
    address: 'Sector 51, Noida',
    story:
      'I have always been an avid reader; ever since childhood, books have been my solace and source of wondrous worlds. In real life, I traversed the worlds of engineering, MBA, banking - the full circle, before landing back on my first love - books. With a small kid of my own who has taken to reading, I feel excited and obliged to share this world of reading with young minds, and to know and listen more to their imagination and stories. With Töölö  I hope to achieve a more well read community, and children who willingly move from screen to paper – and enter the realm of reading and storytelling.',
    imgPath: 'arpita.jpg',
  },

  {
    name: 'B.Charanya',
    designation: 'Töölö Ambassador',
    address: 'Mahalingapuram, Pollachi',
    story:
      "I'm Charanya, a storyteller who's passionate about sharing tales, especially with children. I love engaging with young minds and exploring different storytelling styles, both online and in person. Indian mythology is a particular interest of mine, and I'm eager to bring its magic to kids. Reading aloud with children is something I enjoy, and I'm on a mission to introduce them to the joys of reading books.\n In my area, we don't have a library, and I've got this dream of starting one with Töölö. It's all about nurturing the reading habit in kids, which, in my opinion, opens doors to unimaginable worlds. I'm thrilled to be a part of Team Töölö !",
    imgPath: 'charanya.jpg',
  },
  {
    name: 'Bharti Prakash',
    designation: 'Töölö Ambassador',
    address: 'Preschool Name : Eurokids, RT Nagar',
    story:
      'Mrs. Bharti Prakash is an early childhood educator with 19 years of experience in operating a preschool in Bangalore city, India. She is in the profession of molding young minds using mindful and holistic teaching practices. She has laid the foundation for more than 4000 children in her preschool and has guided and consulted many parents on the right practices of parenting. She holds degrees in Psychology and Sociology, and is a certified counselor, teacher`s trainer, and a successful edupreneur. She is also a source of women empowerment, providing employment opportunities to many women, along with moral and financial support.',
    imgPath: 'bharti.jpg',
  },
  {
    name: 'Sweta Saraf',
    designation: 'Töölö Ambassador',
    address: 'Goregaon, Mumbai',
    story:
      'I am Sweta, a proud mother of a curious little boy who loves books and started reading by himself at an early age. I am also a Chartered Accountant and a trained Montessori teacher. While living in the UK, we benefited from access to good libraries where I introduced my son to the world of books. Inspired by our son’s love for reading and my experience as a Montessori teacher, I wanted to start a children’s library when I moved back to India. My search led me to Töölö and I decided to become a Töölö Ambassador. I look forward to inculcating a love for books and reading in many more children.',
    imgPath: 'sweta.jpg',
  },
  {
    name: 'Rashmi Kotecha',
    designation: 'Töölö Ambassador',
    address: 'Science City Road, Sola, Ahmedabad',
    story:
      'I am Rashmi Kotecha, mother of two. Seeing the way kids around us are glued to their phone the whole day encouraged me to look for an alternate way to engage them. Library was the first option that popped up in all the discussions I had with my family.\n I got to know about Töölö through my husband, whose business is related to library services. Töölö made things easier in helping me fulfill my dream. I’ve always wanted to do something productive from the comfort of home, and Töölö is the perfect fit for me.',
    imgPath: 'rashmi.jpg',
  },
  {
    name: 'Arti Gupta',
    designation: 'Töölö Ambassador',
    address: 'Lohegaon, Pune',
    story:
      'I am Arti, mother of two knowledge hungry kids, and a proud home maker. The search for more and more books for my kids landed me on to Toolo platform. This is something I always wanted for my kids and by being Toolo’s Ambassador, I have an opportunity to serve the needs of other kids and mothers like me as well. I am sure reading books will bring joy to many. Happy to be a part of Toolo’s family.',
    imgPath: 'arti.jpg',
  },
  {
    name: 'Nalini Nawal',
    designation: 'Töölö Ambassador',
    address: 'Ravet, PCMC, Pune',
    story:
      'I am Nalini, mother of two wonderful daughters. I believe wisdom is divine and knowledge is the key to wisdom. One can enhance not only knowledge but also feel essence of wisdom by reading more. \n Töölö is a platform that would be helping me to achieve these goals for our neighborhood and society at large. As a Töölö ambassador, I get the opportunity to multiply the readers’ community and bring joy and happiness to young readers.',
    imgPath: 'nalini.jpg',
  },
  {
    name: 'Banupriya Ramesh',
    designation: 'Töölö Ambassador',
    address: 'Mahindra World City, Chennai',
    story:
      'I am Banupriya, mother of two beautiful girls. I used to read lot of books when I was at school. It feels great to explore them for the sheer curiosity of what the endings of those books would be, but all of that gradually reduced as I aged. I want to cultivate the habit of reading for my kids and their friends. So, when I heard about Toolo I was super excited! By starting a library I believe that I can encourage few children to get into the habit of reading, and get that excitement that I did.',
    imgPath: 'banupriya.jpg',
  },
  {
    name: 'Ketaki and Swapna',
    designation: 'Töölö Ambassador',
    address: 'Prabhat Road, Pune',
    story:
      "We got to know each other through our daughters. We both share a passion for children's literature, and love reading out to our girls. While we have our own professions- Ketaki is a landscape architect, and Swapna is a teacher - we were on the look out for projects to combine our passion, with our commitment to our daughters. We want to spread the joy of reading in our neighbourhood through the Töölö library by not only making books accessible, but also encouraging responsible reading among the younger generations in the community.",
    imgPath: 'ketaki.jpg',
  },
  {
    name: 'Jothirmaya Sanjith',
    designation: 'Töölö Ambassador',
    address: 'Kadubeesanahalli, Bengaluru',
    story:
      'I am Jothirmaya, a happy wife and a mother of two. I’ve been working in corporate for over 15 years. My reading journey began in childhood when my father introduced me to regional language books. The addiction to reading continued until college but took a backseat after getting into corporate. With much determination, I’ve revived the reading habit post motherhood. I joined Töölö to inculcate the reading habit in my kids too. \n Töölö is a great platform to promote and emphasise reading habit among children, and I wish to take it beyond my family and impact the society at large.',
    imgPath: 'jothirmaya.jpg',
  },
  {
    name: 'Pooja Naik Nimbalkar',
    designation: 'Töölö Ambassador',
    address: 'Kothrud, Pune',
    story:
      'An avid reader from the age of 7, I always sought different experiences and solace in the world of books. Introducing my daughter to books from a very tender age came naturally to me and that’s when I stumbled upon Töölö. It also appeared to me as an opportunity to share my love and passion for reading with an entire community. I believe that reading is the key to lifelong learning and that it is essential to start at an early age. With Töölö, I am delighted to be contributing my tiny bit to shaping young minds and making a difference.',
    imgPath: 'pooja.jpg',
  },
  {
    name: 'Pallavi Jha',
    designation: 'Töölö Ambassador',
    address: 'Hope Farm, Whitefield, Bengaluru',
    story:
      'I am Pallavi, mother of two, a counselor, early childhood educator and book lover. I was introduced to the joy of reading by my grandmother at an early age and I was super excited to see my young ones share this passion of mine. Books have been my constant companion in my life and I simply love the conversations that a good story sparks. Töölö helped me realize that I can share and pass on the joy of reading to many more children. Excited to be part of this journey!',
    imgPath: 'pallavi.jpg',
  },
  {
    name: 'Ashwini Benegal',
    designation: 'Töölö Ambassador',
    address: 'Sahakarnagar, Bengaluru',
    story:
      'Books are a portal to another world; an amazing world which is filled with stories of wonderful people and places.  With a book in your hand, you can never feel lonely. \n We are a family of avid readers and so is my ten-year-old daughter.  With the flick of a book page, she can be solving mysteries with the Famous Five or travelling to magical lands with Moon-Face and friends. Boredom is a word she does not know, thanks to her book friends. As a Töölö ambassador I hope to spread the same joy that my family experiences through reading to all the lovely kids in our neighborhood and make these wonderful book buddies accessible to them.',
    imgPath: 'ashwini.jpg',
  },
  {
    name: 'Suma Bhogiraj',
    designation: 'Töölö Ambassador',
    address: 'Happy Garden Layout, Seegehalli, Bengaluru',
    story:
      "I am Suma, a mother, media professional, start-up enthusiast, yoga-lifer and  book lover. I am thankful to my father for introducing me to the joy of reading in my childhood by bringing home many books and subscribing to various magazines like Reader's Digest and Chandamama. My love for books has only grown and has overflown to my daughter who loves books and has made a collection of books for herself.\n When I found Töölö, I realized that it is such a great opportunity to spread the love for books to children around me. There is nothing that can be compared to the pride that comes from assisting a child to discover the joy of reading and the magic of books. Glad to have got the opportunity now because of Töölö. Proud to be an ambassador.",
    imgPath: 'suma.jpg',
  },
  {
    name: 'Vrushali Gorade',
    designation: 'Töölö Ambassador',
    address: 'Borewell Road, Whitefield, Bengaluru',
    story:
      "Staying in a nuclear family, books have always been a part of our lives, especially my son's. Reading from an early age helped him a lot when he started schooling.\n After doing my Bachelor's in Engineering and working in corporate for a  few years, I took a career break for enjoying motherhood. Serving as a primary school teacher later, I realised the necessity of reading for children and wanted to do something to help this.\n Thanks to Töölö for providing this wonderful platform that goes beyond school and helps children access a different world through curated books. I feel blessed to be a Töölö ambassador and to contribute in shaping young minds!",
    imgPath: 'vrushali.jpg',
  },
  {
    name: 'Onezah Choonawala',
    designation: 'Töölö Ambassador',
    address: 'NRI Complex, Seawoods, Navi Mumbai',
    story:
      'I am a trained Therapist and Emotional Wellness Coach with a corporate background in consumer understanding.\nSince I was a child, reading has always been a way of life. I loved it so much that as an 8-year-old, I wanted to become an author. Today, I have realized this wish and have written books for children. Having a library of my own was always a dream. Having the opportunity to open a children’s library feels like a God given gift. Thank you Töölö for being an enabler and a manifestation of my dearest goals.',
    imgPath: 'onezah.jpg',
  },
  {
    name: 'Cheryll Gokul',
    designation: 'Töölö Ambassador',
    address: 'Mahadevapura, Bengaluru',
    story:
      'I am a story teller who loves telling kids stories and making their imagination soar high. I am also a people’s person and love meeting new people and learning about their lives. \n Töölö is an amazing platform and I am excited to be an ambassador and help kids read and develop their confidence. I am sure that with this amazing platform of Töölö and my love for storytelling, I can definitely be a tool to enhance the lives of children.',
    imgPath: 'cheryll.jpg',
  },
  {
    name: 'Malini Diwakar',
    designation: 'Töölö Ambassador',
    address: 'Chandapura, Bengaluru',
    story:
      'My love for reading started with my father getting me my first library card at a public library when I was 8 years old . My childhood was spent in the wonderful fantasy worlds of The Faraway Tree and The Wishing Chair; I lost myself solving mysteries with The Secret Seven and The Famous Five. As a mother of two young kids, I have read to them, told them stories and I cherish their happiness when they open a new book. Töölö gives me the opportunity to bring this experience to a new generation of young readers and grow a community with a love for books.',
    imgPath: 'malini.jpg',
  },
  {
    name: 'Jacqueline and Rose Gomez',
    designation: 'Töölö Ambassador',
    address: 'Jayanagar, Bengaluru',
    story:
      'Rose: For me, reading is a fun and exciting diversion from studying and school activities. Reading a book immediately transports me to the world of literary characters. With a lot of encouragement from my family, I decided to start a library to take on new responsibilities and to seize the opportunity to help other children and teens strengthen their reading habit. \n Jacqueline: Starting a library made a lot of sense for me as a parent, as Rose finishes books in minutes and there aren’t many good libraries in our local community.',
    imgPath: 'rose.jpg',
  },
  // {
  //   name: 'Neethashree Srinivas',
  //   designation: 'Töölö Ambassador',
  //   address: 'Basaveshwar Nagar, Bengaluru',
  //   story:
  //     "A 'never grow old mommy' for two precious tiny angels! By profession, I am a communication consultant for NGOs. I'm also a spiritual practitioner who is constantly surrounded by crystals and oils. \n Reading has always been a true companion to me. I grew up watching my mother read one book a day, and the size of the book didn't matter to her. The wonderful thing I see now is my kids carrying on the legacy. It didn't take long for me to connect with Töölö after seeing an ad on Instagram. This is something I've always wanted to do. \n It appeared to me to be an opportunity with a mix of sustainability, passion and flexibility. Above all, carrying on the legacy of reading by influencing future generations. \n I am delighted to report that I am a Töölö Ambassador!",
  //   imgPath: 'neethashree.jpg',
  // },
  {
    name: 'Neethi Muralidharan',
    designation: 'Töölö Ambassador',
    address: 'Cooke Town, Bengaluru',
    story:
      "I am Neethi, mother of an enthusiastic 4-year-old and an entrepreneur by profession. I have always been trying to explore ways of learning for my daughter. Töölö was introduced to me through a friend and I was really excited about this amazing concept. \n I believe 'Reading is dreaming with open eyes.' I was motivated to become a Töölö Ambassador as I wished to inculcate the reading habit in my little one and the community at large. \n 'Children are made readers on the lap of their parents.' - Emilie Buchwald. \n Glad to be a Töölö Ambassador! ",
    imgPath: 'neethi.jpg',
  },
  {
    name: 'Srikanya Sreekanth',
    designation: 'Töölö Ambassador',
    address: 'Girinagar, Bengaluru',
    story:
      "I am Srikanya, mother of two grown-up kids and a preschool teacher. Being a voracious reader myself, I introduced the book reading habit to my kids 10 years ago, by joining a library. That is the best decision  I have taken for my children. \n 'Good books, like good friends are few and chosen, the more select, the more enjoyable.' \n When I came to know about Töölö community library through social media, I jumped at the opportunity to become an Ambassador so that I can give the same joy of reading to all the kids in my community.",
    imgPath: 'srikanya.jpg',
  },
  {
    name: 'Mira Swarup',
    designation: 'Töölö Ambassador',
    address: 'Sector 66, Gurgaon',
    story:
      "I am an army brat who uses the charm of the olive greens to help professionals gain impact & influence at work by shifting the way they communicate and command presence in any room. I am a Coach & Speaker for Impact Communication and Executive Presence. \n I deeply believe that great quality reading has a significant role in developing us as charismatic geniuses who further shape the world. I find immense purpose in being a Töölö Ambassador where I can help shape young minds by offering them a space where reading isn't just encouraged but is offered with arms wide open.",
    imgPath: 'mira.jpg',
  },
  // {
  //   name: 'Farisa Sheik',
  //   designation: 'Töölö Ambassador',
  //   address: 'Electronic City Phase 1, Bengaluru',
  //   story:
  //     "I am a mother of two young kids. I love travelling,  exploring new places and ideas. More importantly, I enjoy spending time with my little ones who always buzz around me with their curious questions.\n As a mother, I am particular about inculcating good ideas and thoughts in them. Books have helped me a lot in achieving this. I believe having 'book time' is a good therapy for our mind.  My kids love this and find it relaxing  as they travel into an imaginary world. \n Their love for books have inspired me to join hands with Töölö.  What's more interesting than having a library at home and spreading this joy in the neighbourhood? Glad to be a Töölö Ambassador!!",
  //   imgPath: 'farisa.jpg',
  // },
  {
    name: 'Prachi Kamat',
    designation: 'Töölö Ambassador',
    address: 'Murugeshpalya, Bengaluru',
    story:
      "I am Prachi, a mother of 2 fantastic kids and an entrepreneur by profession . Coming from a family of avid readers I couldn't be left far behind and I have loved reading from a young age. Having spent the last ten years running a preschool in my city, I think Töölö gives a me wonderful platform to bring my passion for kids education and books together in a fun way and engaging way. Superb collection of books to suit the needs of curious minds! Totally looking forward to make readers out of more and more kids!",
    imgPath: 'prachi.jpg',
  },
  {
    name: 'Shruthi Shivakumar',
    designation: 'Töölö Ambassador',
    address: 'Bengaluru',
    story:
      "My journey with books began with the tiny biographies of Mother Theresa, Sir M Visveshwaraiah and Dr Ambedkar. The journey continued with me getting hooked to an illustrative Human Anatomy book (thanks to my doctor-dad!) and ending up taking a stream of Biology for study! I still fondly remember shopping for books as a child daily, during the 'Sahitya Sammelan' held in our town. My journey with books has been amazing. Books were my best friends and they still continue to be. I want my kids and all the kids around to befriend books and hence I joined hands with Töölö. And thus, began my latest and adventurous journey with books!",
    imgPath: 'shruti.jpg',
  },
  {
    name: 'Howard Berlie',
    designation: 'Töölö Ambassador',
    address: 'Brigade Meadows, Bengaluru',
    story:
      "I'm a marine engineer by profession and what I love other than work is to read, travel and volunteer. My motivation for signing up to be a Töölö Ambassador is to inculcate the habit of reading in young minds.\n I wanted to encourage my niece and nephew to read but didn’t know how. One day, I found an old Tintin comic in my collection and decided to do a dramatic reading with them. A couple of hours of undivided attention and enacting silly voices was all it took to get them interested in reading. They started with comics and now devour volumes of books in a month. That transformation has left a lasting impression on me. I am glad I spent those hours reading with them - it really did make a difference.",
    imgPath: 'howard.jpg',
  },
  {
    name: 'Neelam Goyal',
    designation: 'Töölö Ambassador',
    address: 'Adarsh Palm Retreat, Bengaluru',
    story:
      'I am a mother to a 8-year-old, and he has become an avid reader due to Töölö.\n I find books are the best friends of humans and it is a very important part of growing up. It is a fun way of connecting to your kid and keeping the curious brain active. I am in love with my Töölö Library (room) and we call it, ABC - Adventurous Books Corner :)\n I believe kids should be encouraged to befriend books and keep learning and growing smarter.\n As they always say there is only one thing which can replace a book: The Next Book.',
    imgPath: 'neelam.jpg',
  },
  // {
  //   name: 'Nikita Sanchar',
  //   designation: 'Töölö Ambassador',
  //   address: 'North Goa',
  //   story:
  //     'A mother to two super energetic kids, I am a woman entrepreneur who believes in striking a balance in all the roles of my life. I have done my Master\'s in Advertising and Marketing and a Baker by passion. I love to travel and I can\'t live without music. Now a proud Töölö Ambassador.\n "Your story is what you have, what you will always have. It is something to own" by Michelle Obama is a quote that inspires me everyday to write my own story each day.',
  //   imgPath: 'nikita.jpg',
  // },
  {
    name: 'Ashita K. Sharma',
    designation: 'Töölö Ambassador',
    address: 'DLF Capital Greens, New Delhi',
    story:
      'I am a mother to a 5-year-old, and I have always been an enthusiast in exploring new ways of learning for my little one.\n Töölö was introduced to me through a friend and I instantly fell in love with the idea of sharing and learning through books. The brilliant concept of having a library of books accessible to all the curious brains around me motivated me to spread the joy of reading through this amazing service to other children in my society too.\n Today, as a Töölö Ambassador, I have introduced this service to many families in my society. And within no time, I can see a real positive change in the outlook and the knowledge bank of all the kids around me. I can proudly say with Töölö, kids in my society are getting smarter, independent & certainly happier!!',
    imgPath: 'Screenshot-20200912-185442.jpg',
  },
];

export const preschool = [
  {
    preschoolName: "Everest's IRIS FLORETS Preprimary School",
    imagePath: 'Irislogo.jpg',
  },
  {
    preschoolName: 'Shemrock',
    imagePath: 'shemrock.png',
  },
  {
    preschoolName: 'MapleBear',
    imagePath: 'library10.png',
  },
  {
    preschoolName: 'Little Aryans',
    imagePath: 'library9.png',
  },
  {
    preschoolName: 'Wonder Cubs',
    imagePath: 'library8.png',
  },
  {
    preschoolName: 'Mount Litera',
    imagePath: 'mount-literia.png',
  },
  {
    preschoolName: 'Boond',
    imagePath: 'library6.png',
  },
  {
    preschoolName: 'Penguins Play School & Day Care',
    imagePath: 'library5.png',
  },
  {
    preschoolName: 'Sparkling Kids Montessori',
    imagePath: 'library4.png',
  },
  {
    preschoolName: 'The Childs Kingdom',
    imagePath: 'library3.png',
  },
  {
    preschoolName: 'Eurokids',
    imagePath: 'library2.png',
  },
  {
    preschoolName: 'Zero Limits Montessori',
    imagePath: 'library1.png',
  },
];
